//import { UTTARPRADESH } from "../../dropdownConst";

const INITIAL_STATE = {
  selectedState: "Uttar Pradesh",
  selectedCity: "Agra",
  selectedWard: "",
};

const NavbarSelection = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_STATE":
      return {
        ...state,
        selectedState:
          action.payload === "Uttar pradesh" ? "Uttar Pradesh" : action.payload,
      };

    case "SET_CITY":
      return {
        ...state,
        selectedCity: action.payload,
      };

    case "SET_WARD":
      return {
        ...state,
        selectedWard: action.payload,
      };

    case "REMOVE_STATE":
      return {
        ...state,
        selectedState: "",
      };

    case "REMOVE_CITY":
      return {
        ...state,
        selectedCity: "",
      };

    case "REMOVE_WARD":
      return {
        ...state,
        selectedWard: "",
      };

    case "REMOVE_ALL":
      return {
        ...state,
        selectedState: "",
        selectedCity: "",
        selectedWard: "",
      };

    default:
      return state;
  }
};

export default NavbarSelection;
